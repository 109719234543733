<style lang="scss" scoped>
    @import 'styles/common.scss';

</style>


<script>
    import { Component, Watch, Vue } from 'vue-property-decorator';

    import AppState from './AppState.vue';
    import Settings from './UserSettings.vue';
    import templ8r from 'libs/templ8r';

    @Component
    export default class Page extends Vue {

        mounted() {
            if (this.args) {
                this.routeToAnchor(this.args);
            }else {
                window.scrollTo(0,0); 
            }
        }

        routeToAnchor(anchor) {
            let element = document.getElementById(anchor);
            if (element) {
                console.log(`Seeking anchor element: '${anchor}'`);
                const y = element.getBoundingClientRect().top - 70;
                //element.scrollIntoView({behavior: 'smooth'}, true);
                console.log("scroll to " + y)
                window.scrollTo({ top: y, behavior: 'smooth'});
            }
        }

        signIn() {
            this.$parent?.signIn();
        }

        signOut() {
            this.$parent?.signOut();
        }

        navigate(route, args) {
            if(this.route === route) {
                this.$parent.navigate(route, args);
                this.routeToAnchor(args);
            }else {

                this.$parent.navigate(route, args);
            }
            
        }

        async checkout() {
            return this.$parent.checkout();
        }

        // async checkoutComplete() {
        //     return this.$parent.checkoutComplete();
        // }

        async portal() {
            return this.$parent.portal();
        }

        async mail(name, email, message) {
            return this.$parent.mail(name, email, message);
        }        
        
        get dal() {
            return this.$parent?.dal;
        }

        get analytics() {
            return this.$parent?.analytics;
        }

        get route() {
            return this.$parent?.route;
        }
        
        get args() {
            return this.$parent?.args;
        }

        get settings() {
            return Settings;
        }

        get state() {
            return AppState;
        }

        get authenticated() {
            return this.state.user !== null;
        }

        get authenticationProvider() {
            if (this.authenticated) {
                return this.state.jwt.firebase.sign_in_provider;
            }
            return null;
        }

        get cart() {
            return this.state.cart;
        }

        get catalog() {
            return this.state.catalog;
        }

        get products() {
            return this.state.products;
        }

        get prices() {
            return this.state.prices;
        }

        get promotions() {
            return this.state.promotions;
        }

        get catalogLoaded() {
            return Object.keys(this.catalog).length > 0 && Object.keys(this.products).length > 0 && Object.keys(this.prices).length > 0;
        }

        get purchases() {
            return this.state.purchases;
        }

        get user() {
            return this.state.user;
        }

        get photo() {
            return this.user?.photoURL;
        }

        get region() {
            return this.state.region;
        }

        get catalogProvider() {
            return this.state.catalogProvider;
        }

        get upgradeDiscount() {
            return this.state.upgradeDiscount;
        }

        makeBold(input, wordsToBold) {
            return input.replace(new RegExp('(\\b)(' + wordsToBold.join('|') + ')(\\b)','ig'), '$1<b>$2</b>$3');
        }

        emphasizeText(text, words) {
        if (!text || !words) {
            return null;
        }
        return this.makeBold(text, words.split(' '));
    }

        replaceServerUrls(url) {
            return templ8r(url, {
                STORESERVER:    AppState.config.url.store,
                WEBSERVER:      AppState.config.url.web,
                TILESERVER:     AppState.config.url.tiles,
            })
        }
    }

</script>